* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

section {
  margin: 10px;
}


body {
  background-color: rgb(202, 220, 226);
}

input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  margin: 5px;

}

label {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
  margin: 5px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
}


img {
  width: 40%;
  text-align: left;
  margin: 10px;
  padding: 7px;
  border-radius: 15px;
  margin-left: 40px;
}


h2 {
  text-align: center;
  color: #287951;
  font-family: 'Graduate', serif;
  font-size: 35px;
  margin-bottom: 30px;
  font-weight: bold;
}



h1 {
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
  font-size: 50px;
  color: #3e5359;
  text-align: center;
  font-family: 'Graduate', serif;
  text-decoration: underline;
}

.myH {
  overflow: hidden;
  text-align: center;
  font-size: 50px;
  color: #3e5359;
  text-align: center;
  font-family: 'Graduate', serif;
  text-decoration: underline;
}


header {
  background-color: #15675f;
  color: #135a6c;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-bottom: 30px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  height: 40px;
}


.logo {
  display: block;
  font-size: 28px;
  font-weight: bold;
  color: #3498db;
  width: 15%;
}



nav ul {
  list-style: none;
  display: flex;
}

nav li {
  margin-right: 20px;
  position: relative;
}

nav a {
  text-decoration: none;
  color: #d3edeb;
  position: relative;
  transition: color 0.3s ease-in-out;
  font-family: "Graduate", serif;
  font-size: 20px;
}

nav a::after {
  content: '';
  display: block;
  height: 2px;
  width: 0;
  background-color: #d3edeb;
  position: absolute;
  bottom: -5px;
  transition: width 0.3s ease-in-out;
}

.logos {
  width: 3%;
  display: block;
  float: right;
  margin-left: 1000px;
}

nav a:hover {
  color: #ffffff;
}

nav li:hover a::after {
  width: 90%;
}

.userInput {
  font-weight: bold;
  color: rgb(36, 66, 122);
  text-decoration: underline;
}




.ops {
  color: rgb(156, 48, 48);
  margin-top: 50px;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ..............GRID CSS............. */

.fContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin-top: 100px;
  margin-left: 100px;
}



.myLogo {
  grid-column: span 3;
  display: flex;
  width: 25%;
}

.myHL {
  color: white;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  margin-top: 30px;
}


form {
  grid-column: span 3;
  display: grid;
}

.landingF {
  grid-column: span 3;
  display: grid;

}

#fName,
#password,
#email,
#number,
#address,
#confirmPassword {
  width: 90%;
  padding: 8px;
}

.submit {
  width: 50%;
  background-color: #51a185;
  color: white;
  border: none;
  text-align: center;
  font-size: 16px;
  margin-left: 65px;
  cursor: pointer;
  text-align: center;
}

.submit:hover {
  background-color: #333;
}

.myBacks {
  margin-top: 10px;
  color: hsl(150, 54%, 19%);
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.myBacks:hover {
  color: rgb(0, 128, 255);
  text-decoration: none;

}


.pImage {
  display: flex;
  flex-direction: column;
  margin-top: -540px;
  width: 40%;
}

.divider {
  width: 3px;
  height: 100vh;
  background-color: #0e6e7e;
  margin: 0 auto;
  margin-right: 560px;
  margin-top: -693px;
}

.pImage img {

  margin-left: 300px;
  width: 60%;
}

.fPara {
  background-color: #a6cbd3;
  border-radius: 10px;
  width: 50%;
  font-size: 18px;
  margin-top: 5px;
  line-height: 28px;
  padding: 20px;
  margin-top: -10px;
  text-align: left;
  margin-left: 100px;
  color: #12283b;
  height: 210px;
  font-family: 'Patua One', serif;
}

.home-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

}

.category-container {
  display: flex;
  justify-content: center;
  align-items: center;

  grid-column: span 4;
}

.item-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  flex-direction: row;
  grid-column: span 4;

}

.categories {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px;
  border-radius: 25px;

}

.item-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  padding: 20px;
  grid-column: span 4;
  border: 2px solid #ffffff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.prod {
  display: block;
  margin: 20px auto;
  width: 90%;
  max-width: 300px;
}

.prods {
  display: block;
  margin: 0 auto;
  width: 70%;
}

.error {
  border: 2px solid red;

}

.pTag {

  font-size: 18px;
  color: #0aa10a;
  font-family: 'Patua One', serif;
  margin: 10px;
}

.pTags {

  font-size: 15px;
  color: #010101;
  font-family: 'Patua One', serif;
  margin: 10px;
}

.item-container p {

  margin-bottom: 10px;
  font-size: 17px;
  color: #138768;
  font-family: 'Patua One', serif;
}



.cartImg {
  display: inline-block;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  width: 50%;
}


.cartBtn {
  border: none;
  background-color: white;
}

.item-container .myButton {
  margin-top: 15px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  width: 30%px;
  border: none;
  background-color: #5b925e;
  transition: background-color 0.3s ease;

}

.myButton:hover {
  background-color: #135a6c;
}


.myButtons {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  background-color: #5b925e;
  transition: background-color 0.3s ease;
  width: 20%;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: -25px;
  text-decoration: none;
  text-align: center;
  font-family: 'Graduate', serif;
}

.myButtons:hover {
  background-color: #135a6c;
}

.itemHeader {
  font-weight: bold;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  font-family: 'Arvo', serif;
  margin-top: 10px;
  margin-bottom: 15px;
}

.itemHeaders {
  font-weight: bold;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Arvo', serif;
  margin-right: 60px;

}


.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  padding: 20px;
  margin-top: -50px;
  text-align: center;
  border-radius: 25px;
}




.cart-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  text-align: center;
  margin: 50px;
  background-color: white;
  border-radius: 15px;


}

.item-image {
  width: 50%;
  display: inline-block;

}

.item-details {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.remove-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-buttons {
  display: flex;
  align-items: center;
  margin-left: 140px;
}

.rBtn {
  margin-top: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 8px;
  background-color: #e74c3c;
  color: #fff;
}

.aBtn {
  margin-top: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 8px;
  background-color: #3ca3e7;
  color: #fff;
}

.rBtn:hover {
  background-color: #4acb4a;
}

.checkout-link {
  display: flex;
  justify-content: center;
  align-items: center;
}


.userContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  text-align: center;
  place-items: center;

}

.userForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 30%;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 20px;
  height: 500px;
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.1);

}

.myUser {
  display: block;
  padding: 8px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 auto;
  width: 40%;
  margin-top: -10px;
  margin-bottom: 20px;
}

.myUsers {
  display: block;
  padding: 8px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 auto;
  width: 40%;
  margin-top: 20px;
}

.purchaseHistory {
  margin: 20px auto;
  padding: 20px;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.purchaseHistory h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.purchaseHistory ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.purchaseHistory li {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.purchaseHistory li:hover {
  background-color: #e6e6e6;
}


.purchase-image {
  display: inline-block;
  width: 10%;
}

.checkout-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.total-cost {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #a9d0db;
  margin-bottom: 70px;
}

.total-cost p {
  color: black;
}


.user-info {

  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #a9d0db
}

.user-info p {
  color: rgb(9, 100, 29);
}

.payment-info {
  display: flex;
  justify-content: right;
  flex-direction: column;

}

.total-cost h2,
.user-info h2,
.payment-form h2 {
  margin-bottom: 40px;
  color: #282c34;
}

.payment-form label {
  margin-bottom: 8px;
}

.payment-form select,
.payment-form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.payment-form button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.purchase-link {
  text-decoration: none;
}






.return-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  width: 400px;
}

.userP {
  text-transform: uppercase;
  font-size: 30px;
  margin-top: 40px;
}

.edit-profile-container {
  display: grid;
  place-items: center;

}

.edit-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  max-width: 900px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


}

.form-group {
  margin: 20px;
}

.right-column .form-group {

  margin-left: 30px;
  margin-top: 22px;
}

.form-group input {
  width: 100%;

}

h3 {
  font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  justify-content: center;

}

.bReturn {
  display: block;
  margin: 0 auto;
  background-color: #30597e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  width: 100px;
  text-decoration: none;
}



.submit-button,
.discard-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;

}

.submit-button {
  background-color: #30597e;
  color: white;

}

.discard-button {
  background-color: #ff5151;
  color: #333;

  color: white;
}

.submit-button:hover {
  background-color: #214323;
}

.discard-button:hover {
  background-color: #999;
}

.info-section {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
}

.info-section p {
  font-size: 19px;
}

.info-label {
  font-size: 18px;
  color: #070707;
  font-family: 'Patua One', serif;
  margin: 10px;
  margin-bottom: 10px;
}


.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.forgot-password-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #b8daff;
}

.form-title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.form-description {
  font-size: 16px;
  color: #312b2b;
  grid-column: span 3;
}

.password-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.form-label {
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}




.purchase-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;

}

.summary-box,
.return-box {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.delivery-box {
  height: 130px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.summary-box {
  flex: 1;
  margin-right: 20px;
}

.summary-box h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.summary-box ul {
  list-style: none;
  padding: 0;
}

.summary-box li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-images {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}